import logoDefault from '../assets/images/LP-images/logo-versar.svg'

import diary from '../assets/images/LP-images/illustration-diary.png'
import communication from '../assets/images/LP-images/illustration-communication.png'
import socioemotional from '../assets/images/LP-images/illustration-socioemotional.png'
import events from '../assets/images/LP-images/illustration-events.png'
import messages from '../assets/images/LP-images/illustration-messages.png'
import board from '../assets/images/LP-images/illustration-board.png'

export const LogoImage = {
  light: logoDefault,
}

export const NavLinks = [
  {
    name: 'Sobre',
    url: '#sobre',
  },
  {
    name: 'BNCC',
    url: '#bncc',
  },
  {
    name: 'Agenda Versar',
    url: '#agenda',
  },
  {
    name: 'Parceiros',
    url: '#parceiros',
  },
  {
    name: 'Contato',
    url: '#contato',
  },
]

export const cardsContent = [
  {
    img: socioemotional,
    title: 'Socioemocional',
    resume:
      'Promova reflexões e experiências para desenvolver habilidades socioemocionais em toda comunidade escolar',
    topics: [
      '- Curadoria de conteúdo e disponibilização de materiais de apoio',
      '- Atividades para múltiplas turmas',
      '- Orientações e recursos para conduzir as aulas',
    ],
  },
  {
    img: diary,
    title: 'Diário',
    resume:
      'Compartilhe a rotina dos alunos com a família de forma rápida e prática.',
    topics: [
      '- Lições e aprendizado estendido em casa',
      '- Comunicação e maior participação na aprendizagem ',
      '- Desempenho metrificado dos estudantes e frequência',
    ],
  },
  {
    img: communication,
    title: 'Comunicação',
    resume:
      'Mantenha pais e responsáveis informados sobre o andamento do dia a dia dos estudantes. ',
    topics: [
      '- Compartilhamento direcionado de fotos e vídeos',
      '- Comunicados sobre feriados, reuniões e observações importantes',
      '- Conteúdos informativos',
      ,
    ],
  },
  {
    img: events,
    title: 'Eventos',
    resume: 'Crie e divulgue eventos escolares em um ambiente único.',
    topics: [
      '- Centralização e organização de todos os eventos',
      '- Convites com possibilidades de confirmação de presença',
      ' - Calendário escolar na palma da mão',
    ],
  },
  {
    img: messages,
    title: 'Mensagens',
    resume: 'Estimule o diálogo entre pais, professores e escola.',
    topics: [
      '- Interação via chat personalizado ',
      ' - Moderação de mensagens',
      '- Diálogo em canal de comunicação institucional',
    ],
  },
  {
    img: board,
    title: 'Mural',
    resume: 'Fixe informações importantes com poucos cliques.',
    topics: [
      '- Ambiente para anexar itens importantes',
      '- Disponibilização de documentos e cardápio',
      '- Acesso rápido a boletins individuais',
    ],
  },
]
